import { defineStore } from 'pinia'
import type { Folder, Prompt } from '@prisma/client'
import type { FolderPartial } from '~/types'
import useApiFetch from '~/composables/useApiFetch'
interface iPromptTag {
  value: string
  count: number
}

interface iState {
  prompts: {
    data: Prompt[]
    pending: boolean
  }
  promptTags: {
    data: iPromptTag[]
    pending: boolean
  }
}

const usePromptStore = defineStore('prompts', {
  state: () => {
    const state: iState = {
      prompts: { data: [], pending: true },
      promptTags: { data: [], pending: true },
    }

    return state
  },
  getters: {
    getPromptById: (state) => {
      return (promptId: string) => {
        return state.prompts.data.find(
          (prompt) => prompt.id === parseInt(promptId)
        )
      }
    },
    searchPrompts: (state) => {
      const stringMatch = (item: string = '', term: string = '') => {
        return (
          (item || '').toLowerCase().indexOf((term || '').toLowerCase()) > -1
        )
      }

      const contentMatch = (prompt: Prompt, term: string = '') => {
        return (
          stringMatch(prompt.title, term) || stringMatch(prompt.content, term)
        )
      }

      const tagMatch = (prompt: Prompt, tag: string = '') => {
        return prompt.tags.includes(tag)
      }

      return ({ term = '', tag = '' }: { term: string; tag: string }) => {
        return computed(() =>
          state.prompts.data.filter((prompt) => {
            if (term.length > 0 && tag.length > 0) {
              return contentMatch(prompt, term) && tagMatch(prompt, tag)
            } else if (term.length > 0) {
              return contentMatch(prompt, term)
            } else if (tag.length > 0) {
              return tagMatch(prompt, tag)
            } else {
              return true
            }
          })
        )
      }
    },
  },
  actions: {
    init({ prompts }: iState) {
      this.prompts = prompts
    },

    // ------------------------------------------
    // PROMPT TAGS
    // ------------------------------------------

    async fetchPromptTags() {
      const { data } = await useApiFetch('/prompts/tags')
      this.setPromptTags({ promptTags: data.value })
    },

    setPromptTags({ promptTags }: { promptTags: { data: iPromptTag[] } }) {
      this.promptTags = { ...promptTags, pending: false }
    },

    // ------------------------------------------
    // PROMPTS
    // ------------------------------------------

    addPrompt({ prompt }: { prompt: Prompt }) {
      this.prompts.data.push(prompt)
    },

    async fetchPrompts() {
      const { data } = await useApiFetch('/prompts')
      this.setPrompts({ prompts: data.value })
    },

    setPrompts({ prompts }: { prompts: { data: Prompt[] } }) {
      this.prompts = { ...prompts, pending: false }
    },

    removePrompt({ promptId }: { promptId: number }) {
      this.prompts.data = this.prompts.data.filter(
        (prompt) => prompt.id !== promptId
      )
    },

    updatePrompt({ prompt }: { prompt: Prompt }) {
      this.prompts.data = this.prompts.data.map((p) => {
        if (p.id !== prompt.id) return p
        return prompt
      })
    },
  },
})

export default usePromptStore
